import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledInputProps extends InputStylesType {}

export const StyledInput = styled.input<StyledInputProps>`
  ${reusableInputStyles}
`;
