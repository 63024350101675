import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledLongInputProps extends InputStylesType {}

export const StyledLongInput = styled.textarea<StyledLongInputProps>`
  ${reusableInputStyles}

  height: 72px;
`;
