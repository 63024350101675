import { ModeType } from 'interfaces';
import { useState } from 'react';
import { CloseIcon, SearchIcon } from 'stories/Icons';
import theme from 'stories/theme';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '..';
import { InputIconWrapper, InputWrapper, Wrapper } from '../styles';
import { InputSizeType } from '../types';
import { StyledSearchInput } from './styles';

interface SearchInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: 'default';
  placeholder?: string;
  width?: string | number;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchInput = ({
  mode = 'light',
  size = 'standard',
  state = 'default',
  placeholder,
  width,
  value,
  onChange,
}: SearchInputProps) => {
  const [text, setText] = useState<string>(value ?? '');

  const stateStyles = getInitStateStylesMapper(mode)[state];
  const initSizeStylesMapper = getInitSizeStylesMapper();

  const padding = text
    ? { paddingRight: theme.spacing[10] }
    : { paddingLeft: theme.spacing[10] };

  const sizeStylesMapper = {
    small: {
      ...initSizeStylesMapper.small,
      ...padding,
    },
    standard: {
      ...initSizeStylesMapper.standard,
      ...padding,
    },
    large: {
      ...initSizeStylesMapper.large,
      ...padding,
    },
  };

  const sizeStyles = sizeStylesMapper[size];

  const handleSearchChange = (searchText: string) => {
    setText(searchText);
    if (onChange) {
      onChange(searchText);
    }
  };

  return (
    <Wrapper width={width}>
      <InputWrapper>
        {!text && (
          <InputIconWrapper startIcon={true}>
            <SearchIcon />
          </InputIconWrapper>
        )}
        <StyledSearchInput
          onChange={(event) => handleSearchChange(event.target.value)}
          value={text}
          placeholder={placeholder}
          {...stateStyles}
          {...sizeStyles}
        />
        {text && (
          <InputIconWrapper onClick={() => handleSearchChange('')}>
            <CloseIcon />
          </InputIconWrapper>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
