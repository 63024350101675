import theme from 'stories/theme';
import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface PhoneInputBoxProps extends InputStylesType {}

export const PhoneInputBox = styled.div<PhoneInputBoxProps>`
  .react-tel-input {
    input {
      ${reusableInputStyles}
      font-size: ${theme.fonts.size.base};
      font-family: ${theme.fonts.family.secondary};
      font-weight: ${theme.fonts.weight.normal};
    }

    .flag-dropdown,
    .selected-flag:hover,
    .selected-flag:focus {
      border: none;
      background: none;
    }

    .selected-flag .arrow {
      margin-left: 2px;
      border-top-color: ${theme.colors.grey[400]};
    }

    .selected-flag .arrow.up {
      border-bottom-color: ${theme.colors.grey[400]};
    }
  }
`;
