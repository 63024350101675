import { ModeType } from 'interfaces';
import { useState } from 'react';
import theme from 'stories/theme';
import { Title } from 'stories/Typography';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '..';
import { InputIconWrapper, InputWrapper, Wrapper } from '../styles';
import { InputSizeType, InputStateType } from '../types';
import { StyledMoneyInput } from './styles';

interface MoneyInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: InputStateType;
  label?: string;
  placeholder?: string;
  value?: number;
  width?: string | number;
}

export const MoneyInput = ({
  mode = 'light',
  size = 'standard',
  state = 'default',
  label,
  placeholder,
  value: initValue,
  width,
}: MoneyInputProps) => {
  const stateStyles = getInitStateStylesMapper(mode)[state];
  const initSizeStylesMapper = getInitSizeStylesMapper();

  const sizeStylesMapper = {
    small: {
      ...initSizeStylesMapper.small,
      paddingLeft: theme.spacing[8],
    },
    standard: {
      ...initSizeStylesMapper.standard,
      paddingLeft: theme.spacing[8],
    },
    large: {
      ...initSizeStylesMapper.large,
      paddingLeft: theme.spacing[8],
    },
  };

  const sizeStyles = sizeStylesMapper[size];

  const [value, setValue] = useState(initValue);

  const handleChange = (value: number) => {
    setValue(value);
  };

  return (
    <Wrapper width={width}>
      {label && (
        <Title size="medium" color={stateStyles.labelColor}>
          {label}
        </Title>
      )}

      <InputWrapper>
        <InputIconWrapper startIcon={true} color={theme.colors.grey[400]}>
          $
        </InputIconWrapper>

        <StyledMoneyInput
          type="number"
          placeholder={placeholder}
          value={value || undefined}
          onChange={(e) => handleChange(Number(e.target.value))}
          {...stateStyles}
          {...sizeStyles}
        />
      </InputWrapper>
    </Wrapper>
  );
};
