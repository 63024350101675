import { ModeType } from 'interfaces';
import { useState } from 'react';
import ReactCalendar from 'react-calendar';
import theme from 'stories/theme';
import { Title } from 'stories/Typography';
import { CalendarV2Icon } from 'ui/Icons/CalendarV2Icon';
import { formatDate } from 'utils/helpers/formatDate';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '..';
import { InputIconWrapper, InputWrapper, Wrapper } from '../styles';
import { InputSizeType, InputStateType } from '../types';
import {
  CalendarBox,
  CalendarController,
  CalendarWrapper,
  LabelController,
} from './styles';

interface CalendarInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: InputStateType;
  label?: string;
  placeholder?: string;
  value?: string;
  width?: string | number;
}

export const CalendarInput = ({
  mode = 'light',
  size = 'standard',
  state = 'default',
  label,
  value: initValue,
  placeholder = 'Select date',
  width,
}: CalendarInputProps) => {
  const stateStyles = getInitStateStylesMapper(mode)[state];
  const initSizeStylesMapper = getInitSizeStylesMapper();

  const sizeStylesMapper = {
    small: {
      ...initSizeStylesMapper.small,
      paddingLeft: theme.spacing[10],
    },
    standard: {
      ...initSizeStylesMapper.standard,
      paddingLeft: theme.spacing[10],
    },
    large: {
      ...initSizeStylesMapper.large,
      paddingLeft: theme.spacing[10],
    },
  };

  const sizeStyles = sizeStylesMapper[size];

  const [value, setValue] = useState(initValue);

  const [isVisible, setIsVisible] = useState(false);

  const toggleIsVisible = () => setIsVisible((isVisible) => !isVisible);

  const handleChange = (value: Date) => {
    const formattedDate = formatDate(value, 'mm/dd/yy');
    setValue(formattedDate);
    toggleIsVisible();
  };

  return (
    <>
      <Wrapper width={width}>
        {label && (
          <Title size="medium" color={stateStyles.labelColor}>
            {label}
          </Title>
        )}

        <InputWrapper>
          <InputIconWrapper startIcon={true}>
            <CalendarV2Icon />
          </InputIconWrapper>

          <CalendarController
            {...stateStyles}
            {...sizeStyles}
            border={
              isVisible
                ? `1px solid ${stateStyles.focusBorderColor}`
                : stateStyles.border
            }
            onClick={toggleIsVisible}
          >
            <LabelController
              color={!value ? theme.colors.grey[300] : undefined}
            >
              {value ? value : placeholder}
            </LabelController>
          </CalendarController>
        </InputWrapper>
      </Wrapper>

      {isVisible && (
        <CalendarWrapper>
          <CalendarBox>
            <ReactCalendar
              formatShortWeekday={(_, date) => date.toDateString()[0]}
              calendarType="US"
              value={value ? new Date(value) : undefined}
              onChange={handleChange}
            />
          </CalendarBox>
        </CalendarWrapper>
      )}
    </>
  );
};
