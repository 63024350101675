import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledSearchInputProps extends InputStylesType {}

export const StyledSearchInput = styled.input<StyledSearchInputProps>`
  ${reusableInputStyles}
`;
