import { Path, Svg } from './styles';

interface CalendarV2IconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const CalendarV2Icon = ({
  height = '20px',
  width = '20px',
  color = '#21B4EF',
}: CalendarV2IconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_25107_88935)">
      <Path
        d="M16.6665 2.49967H15.8332V0.833008H14.1665V2.49967H5.83317V0.833008H4.1665V2.49967H3.33317C2.4165 2.49967 1.6665 3.24967 1.6665 4.16634V17.4997C1.6665 18.4163 2.4165 19.1663 3.33317 19.1663H16.6665C17.5832 19.1663 18.3332 18.4163 18.3332 17.4997V4.16634C18.3332 3.24967 17.5832 2.49967 16.6665 2.49967ZM16.6665 17.4997H3.33317V8.33301H16.6665V17.4997ZM16.6665 6.66634H3.33317V4.16634H16.6665V6.66634Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_25107_88935">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
