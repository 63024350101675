import theme from 'stories/theme';
import styled from 'styled-components';
import { hexToRgba } from 'utils/helpers/stringFormatterHelper';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledCalendarInputProps extends InputStylesType {}

export const CalendarController = styled.div<StyledCalendarInputProps>`
  ${reusableInputStyles}

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface LabelControllerProps {
  color?: string;
}

export const LabelController = styled.label<LabelControllerProps>`
  ${({ color }) =>
    color &&
    `
		color: ${color};
	`}
`;

export const CalendarWrapper = styled.div`
  position: relative;
`;

export const CalendarBox = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  width: 400px;
  z-index: 1;

  .react-calendar__navigation {
    padding: 0.5rem;
    display: flex;
  }

  .react-calendar__navigation__label__labelText {
    color: ${theme.colors.grey[700]};
    font-size: 0.938em;
    font-weight: ${theme.fonts.weight.semiBold};
    line-height: 0.8;
    text-align: center;
  }

  .react-calendar__navigation__arrow {
    color: ${theme.colors.primary[500]};
    font-size: 25px;
    padding: 0 1rem;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: ${theme.colors.grey[300]};
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.medium};
    text-align: center;
    abbr[title] {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    padding: 0.8rem 0.5rem;
  }

  .react-calendar__month-view__days__day {
    font-weight: ${theme.fonts.weight.semiBold};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${hexToRgba(theme.colors.grey[700], 0.2)};
  }

  .react-calendar__tile--active {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      font-weight: ${theme.fonts.weight.medium};
      border-radius: 50%;
      color: ${theme.colors.grey[0]};
      background-color: ${theme.colors.primary[500]};
    }
  }

  * {
    font-family: ${theme.fonts.family.secondary};
    border: none;
    background-color: ${theme.colors.grey[0]};
  }
`;
