// / Export input components and share styles across different input types

export * from './CalendarInput';
export * from './EmailInput';
export * from './Input';
export * from './LongInput';
export * from './MoneyInput';
export * from './PasswordInput';
export * from './PhoneInput';
export * from './PinInput';
export * from './SearchInput';

import { ModeType } from 'interfaces';
import theme from 'stories/theme';

export const getInitStateStylesMapper = (mode: ModeType) => {
  const darkMode = mode === 'dark';
  const { primary, grey, red } = theme.colors;

  const initStyles = {
    color: grey[darkMode ? 0 : 700],
    placeholderColor: grey[400],
    backgroundColor: grey[darkMode ? 600 : 0],
    border: ` 1px solid ${grey[darkMode ? 600 : 300]}`,
    focusBorderColor: primary[500],
    cursor: 'default',
    labelColor: darkMode ? grey[500] : undefined,
    padding: `${theme.spacing[3]} ${theme.spacing[3]}`,
    height: 40,
  };

  return {
    default: {
      ...initStyles,
    },
    disabled: {
      ...initStyles,
      backgroundColor: grey[darkMode ? 600 : 200],
      focusBorderColor: initStyles.border,
      placeholderColor: grey[darkMode ? 500 : 400],
      color: grey[darkMode ? 500 : 700],
      cursor: 'not-allowed',
    },
    error: {
      ...initStyles,
      color: red[500],
      placeholderColor: red[500],
      border: ` 1px solid ${red[500]}`,
      focusBorderColor: red[500],
      labelColor: red[500],
    },
  };
};

export const getInitSizeStylesMapper = () => ({
  small: {
    height: 32,
  },
  standard: {
    height: 40,
  },
  large: {
    height: 60,
  },
});
