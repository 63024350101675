import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledEmailInputProps extends InputStylesType {}

export const StyledEmailInput = styled.input<StyledEmailInputProps>`
  ${reusableInputStyles}
`;
