import styled from 'styled-components';

import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledPasswordInputProps extends InputStylesType {}

export const StyledPasswordInput = styled.input<StyledPasswordInputProps>`
  ${reusableInputStyles}
`;
