import styled from 'styled-components';

interface SvgProps {
  height: string;
  width: string;
  rotate?: string;
}

export const Svg = styled.svg<SvgProps>`
  z-index: 1;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`}
`;

export const Path = styled.path``;
