/**
 * utility function used to remove the digits to differentiate same key value of different widgets inside same Section
 * @param inputKey string key to extract field from ex:"investorInfo.nonUsState-2"
 * @returns returns a string ex:"investorInfo.nonUsState"
 */
export const keyFormatting = (inputKey: string) =>
  inputKey?.replace(/-[\d]$/g, '');

/**
 * utility function to extract field from composite keys
 * @param key string key to extract field from ex:"credentials.email"
 * @returns returns a string ex:"email"
 */
export const get2keystring = (key: string) =>
  key?.split('.')?.slice(1)?.join('.');
/*
 ** returns a new string that replaces a number of characters with '*' character
 ** example : hideDigits("HelloWorld", 2) returns "********ld"
 */

export const hideDigits = (value = '', numOfCharToShow = 2) => {
  if (!value || value.length < numOfCharToShow || numOfCharToShow < 0)
    return '';
  const repeatTimes =
    value.length > numOfCharToShow ? value.length - numOfCharToShow : 0;
  return '*'
    .repeat(repeatTimes)
    .concat(value.substr(value.length - numOfCharToShow));
};

export const hideDigitsSeparators = (value = '', numOfCharToShow = 2) => {
  if (!value || value.length < numOfCharToShow || numOfCharToShow < 0)
    return '';
  let returnValue = '';
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char === '-') returnValue = returnValue.concat('-');
    else if (value.length - 1 - i >= numOfCharToShow)
      returnValue = returnValue.concat('*');
    else returnValue = returnValue.concat(value[i]);
  }
  return returnValue;
};
interface Color {
  r: number;
  g: number;
  b: number;
}

const hexToRgb = (hex: string): Color | null => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToRgba = (hex: string, alpha: number) => {
  const color = hexToRgb(hex);
  if (!color) return;
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

/**
 *
 * @param str string word to capitalize ex:"relevent"
 * @returns string ex:"Relevent"
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
